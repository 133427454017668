var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";
class src_default extends Controller {
  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element;
    this.chart = new Chart(element.getContext("2d"), {
      type: this.typeValue,
      data: this.chartData,
      options: this.chartOptions
    });
  }
  disconnect() {
    this.chart.destroy();
    this.chart = void 0;
  }
  get chartData() {
    if (!this.hasDataValue) {
      console.warn("[stimulus-chartjs] You need to pass data as JSON to see the chart.");
    }
    return this.dataValue;
  }
  get chartOptions() {
    return __spreadValues(__spreadValues({}, this.defaultOptions), this.optionsValue);
  }
  get defaultOptions() {
    return {};
  }
}
src_default.targets = ["canvas"];
src_default.values = {
  type: {
    type: String,
    default: "line"
  },
  data: Object,
  options: Object
};
export { src_default as default };
